<!--
* @Author: DY
* @Date: 2020-05-27 14:52:13
* @Description: ''
-->
<template>
    <div class="newUserFrom" v-loading="loading">
        <el-form
            ref="tenantForm"
            :model="form"
            :rules="rules"
            label-width="1.2rem"
            label-position="right"
        >
            <el-col :span="22">
                <el-form-item label="用户名" prop="RealName">
                    <el-input
                        maxlength="20"
                        show-word-limit
                        :disabled="isDisabled"
                        v-model="form.RealName"
                    ></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="22">
                <el-form-item label="登录账号" prop="LoginName" class="loginAccount">
                    <el-input
                        show-word-limit
                        :disabled="isDisabled"
                        v-model="form.LoginName"
                    ></el-input>
                    <p class="fomeListTips el-icon-warning">
                        必须为手机号
                    </p>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="性别" prop="UserGender">
                    <el-radio-group v-model="form.UserGender" size="small">
                        <el-radio-button :label="1">
                            男
                        </el-radio-button>
                        <el-radio-button :label="0">
                            女
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="账号类型" prop="User_Type">
                    <el-select
                        v-model="form.UserType"
                        placeholder="请选择账号类型"
                        clearable
                        :style="{width: '100%'}"
                    >
                        <el-option
                            v-for="(item, index) in User_TypeOptions"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="登录权限" prop="LogonRights">
                    <el-checkbox-group v-model="LogonRights">
                        <el-checkbox label="1">
                            web端
                        </el-checkbox>
                        <el-checkbox label="2">
                            移动端
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-col>
            <el-col :span="22">
                <el-form-item label="状态" prop="State">
                    <el-radio-group v-model="form.State">
                        <el-radio :label="1">
                            启用
                        </el-radio>
                        <el-radio :label="2">
                            暂停
                        </el-radio>
                        <el-radio :label="3">
                            关闭
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="Email">
                    <el-input v-model="form.Email"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="年龄">
                    <el-input v-model="form.UserAge"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="22">
                <el-form-item label="部门组织" prop="selectDepartment">
                    <el-cascader
                        placeholder="请选择部门组织"
                        v-model="form.selectDepartment"
                        ref="ref_tenantTree"
                        :options="tenantTree"
                        :show-all-levels="false"
                        :props="{
                            label: 'OrgName',
                            children: 'Childs',
                            value: 'OrgId',
                            checkStrictly: true
                        }"
                        @change="selectTenant"
                    ></el-cascader>
                </el-form-item>
            </el-col>
            <el-col :span="22">
                <el-form-item label="工号" prop="UserCode">
                    <el-input v-model="form.UserCode"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="22">
                <el-form-item label="角色信息" prop="RoleInfos">
                    <el-select
                        multiple
                        placeholder="请选择角色信息"
                        v-model="form.roleIds"
                    >
                        <el-option
                            v-for="item in roleData"
                            :key="item.Id"
                            :label="item.RoleName"
                            :value="item.Id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-form>
        <div class="dialog-footer">
            <div class="btn save-btn" @click="submitForm">
                保存
            </div>
            <div class="btn close-btn" @click="handleClose">
                关闭
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    props: {
        userId: String,
        editType: {
            type: String,
            default() {
                return 'newUser';
            },
        },
    },
    data() {
        const LoginNameV = (rule, value, callback) => {
            const reg = /^1[3-9]\d{9}$/;
            if (!value) {
                return callback(new Error('请输入登录账号'));
            }
            if (!reg.test(value)) {
                return callback(new Error('登录名称必须为手机号'));
            }
            callback();
        };
        return {
            loading: '',
            isDisabled: false,
            tenantId: '',
            User_TypeOptions: [{
                label: '内部用户',
                value: 1,
            }, {
                label: '司机',
                value: 2,
            }, {
                label: '客户',
                value: 3,
            }, {
                label: '供应商',
                value: 4,
            }, {
                label: '运输单位',
                value: 5,
            }],
            // 登录权限选项
            LogonRights: [],
            // 部门组织数据
            tenantTree: [],
            // 角色信息数据
            roleData: [],
            form: {
                RealName: '', // 用户名称
                LoginName: '', // 登录账号
                Email: '', // Email
                State: 1, // 状态
                UserGender: 1, // 性别
                UserType: 1, // 账号类型
                UserAge: '', // 年龄
                CertificateType: '', // 证件类型
                CertificateNumber: '', // 证件号码
                UserCode: '', // 工号
                UserPost: '', // 职务/岗位/工种
                OrgId: '', // 组织id
                OrgName: '', // 组织名称
                RoleInfos: [], // 角色编码,key为roleId,value为roleName
                roleIds: [], // 选中角色
                TenantId: '', // 租户id
                LogonRights: '', // 登录权限

                // 选择部门组织绑定字段
                selectDepartment: [],
            },
            rules: {
                RealName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { max: 20, message: '长度在 20 个字符以内', trigger: 'blur' },
                ],
                LoginName: [
                    { required: true, validator: LoginNameV, trigger: 'blur' },
                ],
                UserGender: [
                    { required: true, message: '请选择性别', trigger: 'blur' },
                ],
                UserType: [
                    { required: true, message: '请选择账号类型', trigger: 'blur' },
                ],
                State: [
                    { required: true, message: '请选择状态', trigger: 'blur' },
                ],
                selectDepartment: [
                    { required: true, message: '请选择部门组织', trigger: 'blur' },
                ],
                UserCode: [
                    { required: true, message: '请输入工号', trigger: 'blur' },
                ],
                roleIds: [
                    { required: true, message: '请选择角色信息', trigger: 'blur' },
                ],
            },
        };
    },
    watch: {},
    computed: {},
    async created() {
        this.loading = true;
        this.tenantId = this.$takeTokenParameters('TenantId');
        this.tenantTree = await this.getTenantTree(this.tenantId);
        this.roleData = await this.getRoleInfo(this.tenantId);
        if (this.editType === 'editUser') {
            const initData = await this.initRoleInfo(this.tenantId, this.userId);
            const newRoleIds = Object.keys(initData.RoleInfos);
            initData.roleIds = newRoleIds;
            this.form = Object.assign(this.form, initData);
            this.LogonRights = this.form.LogonRights ? this.form.LogonRights.split('|') : [];
            this.loadDepartment(this.tenantTree, this.form.OrgId);
        }
        this.loading = false;
    },
    mounted() {},
    methods: {
        // 初始化部门组织
        loadDepartment(data, id) {
            data.forEach(item => {
                if (item.OrgId === id) {
                    let selectData = [...item.AllParentId.split(','), id];
                    selectData = selectData.filter(item => item !== '');
                    this.form.selectDepartment = selectData;
                }
                if (item.Childs) {
                    this.loadDepartment(item.Childs, id);
                }
            });
        },

        // 选择部门组织
        selectTenant() {
            const thisData = this.$refs.ref_tenantTree.getCheckedNodes();
            if (thisData.length > 0) {
                this.form.OrgName = thisData[0].label;
                this.form.OrgId = thisData[0].value;
            }
        },
        submitForm() {
            this.loading = true;
            this.$refs.tenantForm.validate(async valid => {
                if (valid) {
                    const { roleIds } = this.form;
                    const newRoleInfos = {};
                    roleIds.forEach(item => {
                        this.roleData.forEach(it => {
                            if (item === it.Id) {
                                newRoleInfos[item] = it.RoleName;
                            }
                        });
                    });
                    this.form.RoleInfos = newRoleInfos;
                    this.form.LogonRights = this.LogonRights.join('|');
                    this.form.TenantId = this.$takeTokenParameters('TenantId');
                    let res = null;
                    if (this.editType === 'newUser') { // 新增
                        res = await this.getNewUser(this.form);
                    } else if (this.editType === 'editUser') { // 编辑
                        res = await this.getModifyUser(this.form);
                    }
                    if (res) {
                        this.$message({
                            showClose: true,
                            message: '保存成功！',
                            type: 'success',
                        });
                        this.$emit('handleSucc');
                    }
                }
            });
            this.loading = false;
        },
        handleClose() {
            this.$emit('handleClose');
        },
        // 获取租户树
        getTenantTree(tenantId = '') {
            return this.$axios.$get(`/interfaceApi/platform/tenant_org_manage/tenantorgtree?TenantId=${tenantId}`, { defEx: true });
        },

        // 获取角色信息
        getRoleInfo(tenantId = '') {
            return this.$axios.$get(`/interfaceApi/platform/tenant_role_manage/dropdown_list/${tenantId}`, { defEx: true });
        },

        // 新增用户
        getNewUser(formData) {
            return this.$axios.$post('/interfaceApi/platform/user_manage/create_and_sync', formData, { defEx: true });
        },

        // 编辑初始化用户信息
        initRoleInfo(tenantId = '', orgId = '') {
            return this.$axios.$get(`/interfaceApi/platform/user_manage/info/${tenantId}/${orgId}`, { defEx: true });
        },

        // 编辑用户
        getModifyUser(formData) {
            return this.$axios.$put('/interfaceApi/platform/user_manage/modify_and_sync', formData, { defEx: true });
        },
    },
};
</script>

<style scoped lang="stylus">
.newUserFrom
    .el-form
        overflow hidden
        .loginAccount
            >>>.el-form-item__content
                display: flex
                .fomeListTips
                    color #868fa3
                    font-size 0.12rem
                    margin-left 0.1rem
                    line-height: 0.38rem
                    white-space: nowrap
        .el-form-item
            .el-cascader,
            .el-select
                width 100%
            .el-scrollbar__wrap
                overflow: auto
    .dialog-footer
        padding 0.2rem 0 0;
        display flex
        justify-content center
        .btn
            margin 0 0.3rem;
            width: 1.3rem;
            height: 0.36rem;
            line-height 0.36rem;
            text-align center
            border-radius: 0.01rem;
            cursor pointer
        .save-btn
            background: #1577D2;
            color #fff;
        .close-btn
            border: 0.01rem solid #1577D2;
            color #1577D2
</style>
